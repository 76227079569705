<template>
  <section class="section" :class="background === 'grey' ? 'bg-light' : ''">
    <div class="container">
      <div class="row">
        <ComponentTitle :title="title" :description="description">
        </ComponentTitle>
        <!--        <pre>{{ filteredYachts }}</pre>-->
        <div v-if="loading">
          <div
            class="col-lg-4 col-md-6 col-12 mt-4 pt-2"
            v-for="index in 9"
            :key="index"
          >
            <ListLoader></ListLoader>
          </div>
        </div>
        <div
          v-else
          class="col-lg-4 col-md-6 col-12 mt-4 pt-2"
          v-for="index in filteredYachts"
          :key="index"
        >
          <div v-for="yacht in index" :key="yacht.id">
            <SnippetBoxYachtCard :item="yacht"></SnippetBoxYachtCard>
          </div>
        </div>
        <p v-if="error">{{ $t("home.showcase.errorFetch") }}</p>
      </div>
    </div>
    <div class="col-lg-12 text-center col-md-4 mt-4 pt-2">
      <router-link to="/yachts" class="text-muted readmore btn btn-primary">{{
          $t("home.showcase.button")
        }}</router-link>
    </div>
  </section>
</template>
<script>
import ComponentTitle from "@/components/ComponentTitle";
import SnippetBoxYachtCard from "@/components/SnippetBoxYachtCard";
import axios from "axios";
const axiosRetry = require("axios-retry");
import { ListLoader } from "vue-content-loader";

export default {
  name: "ComponentShowcase",
  components: {
    ComponentTitle,
    SnippetBoxYachtCard,
    ListLoader,
  },
  data() {
    return {
      yachts: [],
      loading: true,
      error: null,
      positions: null,
      filteredYachts: [],
      message: null,
      orderArray: null,
    };
  },
  methods: {
    doMath: function (index) {
      return index + 1;
    },
    async fetchShowcaseItems() {
      try {
        this.error = null;
        this.loading = true;
        axiosRetry(axios, {
          retries: 3,
          retryCondition: axiosRetry.isRetryableError,
        });
        const url = `${process.env.VUE_APP_STRAPI_API_URL}/yacht-showcase/`;
        console.log("Fetching from:", url);
        const response = await axios.get(url);
        const results = response.data || [];
        // console.log("Response Headers:", response.headers);
        // console.log("Response Content-Type:", response.headers["content-type"]);
        // console.log("Response Data:", response.data);
        this.filteredYachts = results;
        // console.log("API URL:", env.VUE_APP_STRAPI_API_URL);
        this.eventBus.emit("preHeight");
      } catch (err) {
        console.log(err);
        if (err.response) {
          this.error = err.message;
        } else if (err.request) {
          this.error = err.message;
        } else {
          this.error = err.message;
        }
        this.eventBus.emit("preHeight");
      }

      this.loading = false;
    },
  },

  created() {
    this.fetchShowcaseItems();
  },
  props: ["title", "description", "background"],
};
</script>