<script>
import SnippetVideoPlayer from "@/components/SnippetVideoPlayer.vue";
import PageIntroComponent from "@/components/PageIntroComponent";
import ComponentShowcase from "@/components/ComponentShowcase";
import ComponentDealership from "@/components/ComponentDealership";
import ComponentContact from "@/components/ComponentContact";

export default {
  components: {
    SnippetVideoPlayer,
    PageIntroComponent,
    ComponentShowcase,
    ComponentDealership,
    ComponentContact,
  },
  data() {
    return {
      videoPoster: "images/video_placeholder.jpg",
      fallbackVideoUrl: "https://static-photos.ams3.digitaloceanspaces.com/static-yachtfull-com/homepageLandingVideos/Landing-Yachtfull-3",
    };
  },
  computed: {
    videoUrl() {
      return this.fallbackVideoUrl;
    }
  }
};
</script>

<template>
  <section class="home-slider position-relative">
    <SnippetVideoPlayer
      :is-large="true"
      :urlVideo="videoUrl"
      :urlPoster="videoPoster"
      v-if="videoUrl"
    ></SnippetVideoPlayer>
  </section>
  <PageIntroComponent
    :title="$t('home.introduction.title')"
    :description="$t('home.introduction.text')"
    :button="$t('home.introduction.button')"
  ></PageIntroComponent>

  <ComponentShowcase
    :title="$t('home.showcase.title')"
    :description="$t('home.showcase.subtitle')"
    background="grey"
  >
  </ComponentShowcase>

  <ComponentDealership
    :title="$t('home.dealerships.title')"
    :description="$t('home.dealerships.subtitle')"
  >
  </ComponentDealership>

  <ComponentContact
    :title="$t('home.contact.title')"
    :description="$t('home.contact.subtitle')"
  >
  </ComponentContact>
</template>
