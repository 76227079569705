<template>
  <div :class="wrapperClass">
    <video-background
      :class="wrapperClass"
      muted
      loop
      :src="selectedVideo + '1080.mp4'"
      :poster="urlPoster"
      :sources="[
        { src: selectedVideo + '1080.mp4', res: 1080, autoplay: true },
        { src: selectedVideo + '240.mp4', res: 240, autoplay: true },
        { src: selectedVideo + '360.mp4', res: 360, autoplay: true },
        { src: selectedVideo + '720.mp4', res: 720, autoplay: true },
      ]"
    >
    </video-background>
  </div>
</template>

<style scoped>
.video-wrapper {
  position: relative;
  top: 0;
  left: 0;
  min-height: 85vh;
  max-height: 90vh;
  width: 100%;
  z-index: 100;
  pointer-events: none;
  overflow: hidden;
}

.video-wrapper-small {
  position: relative;
  top: 0;
  left: 0;
  min-height: 55vh;
  max-height: 60vh;
  width: 100%;
  z-index: 100;
  pointer-events: none;
  overflow: hidden;
}
</style>

<script>
export default {
  data() {
    return {
      loading: false,
      videobgUrls: [
        "https://assets-yachtfull.ams3.digitaloceanspaces.com/landingpage-header-videos/Landing-Yachtfull-3-", // Satellite 44
        "https://assets-yachtfull.ams3.digitaloceanspaces.com/landingpage-header-videos/Landing-Yachtfull-4-", // Old men
        "https://assets-yachtfull.ams3.digitaloceanspaces.com/landingpage-header-videos/Landing-Yachtfull-5-", // Sweden
        "https://static-photos.ams3.digitaloceanspaces.com/landingpage-header-videos/Landing-Yachtfull-6-", // RSC VIDEO HERE - ok
        "https://assets-yachtfull.ams3.digitaloceanspaces.com/landingpage-header-videos/Landing-Yachtfull-7-", // Borkumriff
        "https://static-photos.ams3.digitaloceanspaces.com/landingpage-header-videos/Landing-Yachtfull-11-", // Bestevaer
        "https://static-photos.ams3.digitaloceanspaces.com/landingpage-header-videos/Landing-Yachtfull-10-" // Contest 55
      ],
      selectedVideo: null,
    };
  },
  props: {
    isLarge: {
      type: Boolean,
      default: false
    },
    urlVideo: {
      type: String,
      default:
        "https://static-photos.ams3.digitaloceanspaces.com/landingpage-header-videos/Landing-Yachtfull-11-",
    },
    urlPoster: {
      type: String,
      default: "@/assets/images/video_placeholder.jpg",
    },
    videoUrlHeader: {
      type: String,
      default: "",
    },
  },
  computed: {
    wrapperClass() {
      return this.isLarge ? 'video-wrapper' : 'video-wrapper-small';
    }
  },
  methods: {
    randomItem(items) {
      return items[Math.floor(Math.random() * items.length)];
    },
  },
  created() {
    if (this.videoUrlHeader) {
      this.selectedVideo = this.videoUrlHeader;
    } else {
      this.selectedVideo = this.randomItem(this.videobgUrls);
    }
  },
};
</script>