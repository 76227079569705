<template>
  <div class="container">
    <!-- First check if data from API is loaded -->
    <ContentLoader
      v-if="this.loading === true"
      class="preheight"
      viewBox="0 0 250 110"
    >
      <rect x="0" y="0" rx="3" ry="3" width="100%" height="3" />
      <rect x="0" y="10" rx="3" ry="3" width="100%" height="3" />
      <rect x="0" y="20" rx="3" ry="3" width="100%" height="3" />
      <rect x="0" y="30" rx="3" ry="3" width="100%" height="3" />
      <rect x="0" y="40" rx="3" ry="3" width="100%" height="3" />
      <rect x="0" y="50" rx="3" ry="3" width="100%" height="3" />
      <rect x="0" y="60" rx="3" ry="3" width="100%" height="3" />
      <rect x="0" y="70" rx="3" ry="3" width="100%" height="3" />
      <rect x="0" y="80" rx="3" ry="3" width="100%" height="3" />
      <rect x="0" y="90" rx="3" ry="3" width="100%" height="3" />
      <rect x="0" y="100" rx="3" ry="3" width="100%" height="3" />
      <rect x="0" y="110" rx="3" ry="3" width="100%" height="3" />
      <rect x="0" y="120" rx="3" ry="3" width="100%" height="3" />
      <rect x="0" y="130" rx="3" ry="3" width="100%" height="3" />
      <rect x="0" y="140" rx="3" ry="3" width="100%" height="3" />
      <rect x="0" y="150" rx="3" ry="3" width="100%" height="3" />
      <rect x="0" y="160" rx="3" ry="3" width="100%" height="3" />
      <rect x="0" y="170" rx="3" ry="3" width="100%" height="3" />
      <rect x="0" y="180" rx="3" ry="3" width="100%" height="3" />
    </ContentLoader>
    <!-- if loaded, double check if output has Items and loading as actually been finished -->
    <div v-if="items && this.loading === false">
      <div class="container bg-profile d-table ">
        <SnippetImageSlider
          ref="mainslider"
          v-if="!loading && items"
          :options="primaryOptions"
          :properties="items"
        ></SnippetImageSlider>
      </div>
      <div class="container">
        <YachtPageSidebar
          v-if="!loading && items"
          :properties="items"
        ></YachtPageSidebar>
      </div>
      <!--      <div class="notification">-->
      <!--        <img-->
      <!--          src="@/assets/images/attention-icon.svg"-->
      <!--          width="26"-->
      <!--          class="notification-icon"-->
      <!--          alt="Notification Icon"-->
      <!--        />-->
      <!--        <p class="notification-text">-->
      <!--          Mobile detected. Switch to desktop/tablet for better experience.-->
      <!--        </p>-->
      <!--      </div>-->
      <div class="notification">
        <img
          src="@/assets/images/video-icon.svg"
          width="26"
          class="notification-icon"
          alt="Notification Icon"
        />
        <p class="notification-text">
          <div
                  class="item video"
                  v-if="
          items.media &&
          items.media.videoURL !== null &&
          items.media.videoURL !== ''
        "
                  @click="showModal('video', items.media)"
          >
              <span>{{ $t("brokerage.watch-video") }}</span>
          </div>
        </p>
      </div>
      <!-- Second check if yacht is on PREVIEW status -->
      <div v-if="this.$route.query.preview === 'true'">
        <div class="container">
          <BoatSpecifications :blur="false"></BoatSpecifications>
        </div>
      </div>
      <!-- Third check if yacht is on newly build -->
      <div v-else-if="items.publishing.usedBoat === false" class="mb-5">
        <h4>{{ $t("brokerage.newYacht.title") }}</h4>
        <span
          v-html="
            $t('brokerage.newYacht.description', {
              account: 'hello',
              domain: 'yachtfull.com',
            })
          "
        ></span>
      </div>
      <div class="bg-profile" v-else>
        <!-- Fourth check if yacht is sold or has coming soon status -->
        <div
          v-if="
            items.publishing.sale_status.type !== 'Sold' &&
            items.publishing.comingSoon !== true
          "
        >
          <BoatSpecifications :blur="false"></BoatSpecifications>
        </div>
        <!-- In the following scenario the yacht is either sold and requires an overlay or should get an overlay of coming soon -->
        <div v-else>
          <div class="snippetTabsLayer0">
            <div class="snippetTabsLayer1">
              <img
                v-if="
                  items.publishing.sale_status.type === 'Sold' &&
                  items.publishing.comingSoon !== true
                "
                class="snippetTabsImg"
                src="@/assets/images/sold-placeholder.png"
                alt="Sold"
              />
              <img
                v-if="
                  items.publishing.comingSoon === true &&
                  items.publishing.sale_status.type !== 'Sold'
                "
                class="snippetTabsImg"
                src="@/assets/images/comingsoon-placeholder.png"
                alt="Coming soon"
              />
            </div>
            <BoatSpecifications :blur="true"></BoatSpecifications>

          </div>
        </div>
      </div>

      <div class="h-auto mt-0 pt-5" v-if="loading === false">
        <div class="container">
          <div class="row" v-if="items.publishing">
            <div class="col-12">
              <!--            <h4 v-if="item.Brand && item.Brand.brand !== null">-->
              <h4>{{ $t("contact.questions_title") }}</h4>
              <!--              {{ this.items.Brand.brand.name }}-->
              <!--              {{ this.items.Brand.brand.model }}?-->
              <span
                class="error_message"
                v-if="this.items.publishing.sale_status.type === 'Sold'"
                >{{ $t("brokerage.sold.status") }}</span
              >
              <!--            </h4>-->
            </div>
            <div class="col-12 col-xs-12 col-md-6" v-if="loading === false">
              <ContactForm :yachtid="items.id" :yacht="items"></ContactForm>
            </div>
            <div class="col-12 col-xs-12 col-md-6" v-if="loading === false">
              <SnippetMapMarker :item="items"></SnippetMapMarker>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import SubComponentLabels from "@/components/subComponentLabels";
import YachtPageSidebar from "@/components/YachtPageSidebar";
// import Specifications from '@/components/YachtSpecifications'
import SnippetImageSlider from "@/components/SnippetImageSlider";
// import SnippetTabs from "@/components/SnippetTabs";
import SnippetMapMarker from "@/components/snippetMapMarker";
import ContactForm from "@/components/ContactForm";
import { ContentLoader } from "vue-content-loader";
import BoatSpecifications from "@/components/CBoatSpecifications.vue";
export default {
  layout: "@/layouts/DefaultLayout",
  computed: {},
  data: function () {
    return {
      title: "My Title",
      yachtItem: [],
      yachtAttributes: [],
      loading: true,
      error: false,
      isModalBrochureVisible: false,
      isModalViewingVisible: false,
      isModalVideoVisible: false,
      isModal360Visible: false,
      videoID: null,
      items: null,
      primaryOptions: {
        type: "fade",
        keyboard: true,
        rewind: true,
        pagination: false,
        heightRatio: 0.56,
        autoplay: true,
        cover: true,
        arrows: true,
        lazyLoad: true,
        isModalVisible: false,
      },
    };
  },
  async created() {
    try {
      const includeDetails = await axios.get(
        `${
          process.env.VUE_APP_STRAPI_API_URL
        }/website-inclusion-detail/?&timestamp=${new Date().getTime()}`
      );
      this.propertiesIncluded = includeDetails.data;
      if (this.propertiesIncluded) {
        try {
          const res = await axios.get(
            `${process.env.VUE_APP_STRAPI_API_URL}/yacht-one-extendedinfo/${this.$route.params.id}`
          );
          this.items = res.data;
          // console.log(JSON.stringify(this.items));
          // console.log(this.items.boatName);
          this.eventBus.emit("preHeight");
          if (!this.items) {
            this.$router.push({ name: "yachts" });
          }
        } catch (error) {
          console.log(error);
        }
      }
      this.$nextTick(() => {
        if (this.items) {
          let boatBrandName = '';
          let boatDescription = '';

          if (this.items && this.items?.Brand && this.items?.Brand.brand && this.items?.Brand.brand.name) {
            boatBrandName = this.items?.Brand.brand.name;
          }

          let mainImageUrl = '';

          if (this.items && this.items?.primaryPhoto && this.items?.primaryPhoto.photo && this.items?.primaryPhoto.photo.url) {
            mainImageUrl = this.items?.primaryPhoto.photo.url;
          }

          if (this.items && this.items?.salesPitch && this.items?.salesPitch.extensiveDescription) {
            boatDescription = this.items?.salesPitch.extensiveDescription;
          }
          let boatModel = '';

          if (this.items && this.items?.Brand && this.items?.Brand.model) {
            boatModel = this.items?.Brand.model;
          }
          document.title = `${boatBrandName} ${boatModel} - "${this.items?.boatName}"`;
          this.updateMetaTag('charset', 'utf-8');
          this.updateMetaTag('robots', 'index,follow');
          this.updateMetaTag('canonical', `https://yachtfull.com/brokerage/${this.items?.id}`);
          this.updateMetaTag('description', `${boatDescription}`);

          // Open Graph Tags
          this.updateMetaTag('og:title', `${boatBrandName} ${boatModel} - "${this.items?.boatName}"`);
          this.updateMetaTag('og:description', `${boatDescription}`);
          this.updateMetaTag('og:url', `https://yachtfull.com/brokerage/${this.items?.id}`);
          this.updateMetaTag('og:image', mainImageUrl);
          this.updateMetaTag('og:site_name', `YachtFull`);
          this.updateMetaTag('og:locale', `en_US`);

          // Twitter Card Tags
          this.updateMetaTag('twitter:title', `${boatBrandName} ${boatModel} - "${this.items?.boatName}"`);
          this.updateMetaTag('twitter:card', 'summary_large_image');
          this.updateMetaTag('twitter:site', '@yachtfull');
          this.updateMetaTag('twitter:creator', '@yachtfull');
          this.updateMetaTag('twitter:description', `${boatDescription}`);
          this.updateMetaTag('twitter:image', mainImageUrl);

        }
      });
      this.loading = false;
    } catch (error) {
      this.error = error;
      console.log(error);
      this.loading = false;
    }
  },
  components: {
    SnippetMapMarker,
    SnippetImageSlider,
    YachtPageSidebar,
    ContactForm,
    // SnippetTabs,
    ContentLoader,
    BoatSpecifications,
  },
    methods: {
      showModal(event, properties, media) {
          this.eventBus.emit("showModal", { event, properties, media });
      },
      updateMetaTag(name, content) {
        let meta = document.querySelector(`meta[name="${name}"]`);
        if (meta) {
          meta.setAttribute('content', content);
        } else {
          meta = document.createElement('meta');
          meta.setAttribute('name', name);
          meta.setAttribute('content', content);
          document.getElementsByTagName('head')[0].appendChild(meta);
        }
      },
    },
  props: {
    type: {
      type: String,
      default: "rect",
    },
    size: {
      type: [Number, String],
    },
    animation: {
      type: String,
      default: "wave",
    },
    height: {
      type: [Number, String],
    },
    width: {
      type: [Number, String],
    },
    color: {
      type: String,
      default: "rgba(0, 0, 0, 0.12)",
    },
    waveColor: {
      type: String,
    },
    rounded: {
      type: Boolean,
      default: true,
    },
      radius: {
      type: [Number, String],
      default: 0,
    },
  },
};
</script>

<style scoped>
.notification {
  display: none;
}
@media (min-width: 481px) and (max-width: 767px) {
    .notification {
        display: flex;
        padding: 6px;
        margin: 0 auto;
        background-color: darkslategrey;
        color: white;
        width: 85%; /* This will make the div take up full width */
    }

    .notification-icon {
        margin-right: 10px; /* Adjust this value to add space between the icon and the text */
        color: white;
        align-items: center; /* This will center items vertically */
        justify-content: center; /* This will center items horizontally */
    }

    .notification-text {
        margin: 0; /* This removes default paragraph margins */
        flex: 1; /* This prevents the text from wrapping under the icon */
        align-items: center; /* This will center items vertically */
        justify-content: center; /* This will center items horizontally */
    }
}
@media (min-width: 320px) and (max-width: 860px) {
  .notification {
      display: flex;
      margin: 0 auto;
      padding: 6px;
      margin-bottom: 30px;
      background-color: darkslategrey;
      color: white;
      width: 85%; /* This will make the div take up full width */
  }

  .notification-icon {
    margin-right: 10px; /* Adjust this value to add space between the icon and the text */
    color: white;

      align-items: center; /* This will center items vertically */
      justify-content: center; /* This will center items horizontally */
  }

  .notification-text {
    margin: 0; /* This removes default paragraph margins */
    flex: 1; /* This prevents the text from wrapping under the icon */
      align-items: center; /* This will center items vertically */
      justify-content: center; /* This will center items horizontally */
  }

}
.preheight {
  width: 100%;
  height: 100vh;
}
.snippetTabsLayer0 {
  width: 100%;
  position: relative;
  margin: 0;
  padding: 0;
  height: 100%;
  z-index: 10;
}
.snippetTabsLayer1 {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.snippetTabsImg {
  width: 70%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  opacity: 0.5;
  transform: rotate(-14deg);
  border-radius: 8px;
}
.content {
  background-color: #fff;
  margin: 50px;
  padding: 50px;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
}

@media (max-width: 475px) {
  .content {
    background-color: #fff;
    margin: 10px;
    padding: 10px;
  }

  h5 {
    font-size: clamp(14px, 3vw, 18px);
  }
  .box {
    top: 20vh;
  }
}

.videoWrapper {
  position: relative;
  padding-bottom: 55.25%; /* 16:9 */
  margin: auto;
}

.videoWrapper iframe {
  position: absolute;
  background-color: #06173e;
  opacity: 1;
  z-index: 1000;
  border-radius: 14px;
}
.loader {
  width: fit-content;
  cursor: wait;
  --gradient-color: rgba(255, 255, 255, 0.5);
}

/* Animation definitions */
@keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.85);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes pulse-x {
  0% {
    transform: scaleX(1);
  }
  50% {
    transform: scaleX(0.75);
  }
  100% {
    transform: scaleX(1);
  }
}

@keyframes pulse-y {
  0% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(0.75);
  }
  100% {
    transform: scaleY(1);
  }
}

@keyframes wave {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

/* Animation classes */
.animation--fade {
  animation: fade 1.5s linear 0.5s infinite;
}

.animation--wave::before {
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.5),
    transparent
  );
  background: linear-gradient(
    90deg,
    transparent,
    var(--gradient-color),
    transparent
  );
  animation: wave 1.5s linear 0.5s infinite;
}

.animation--pulse-x {
  animation: pulse-x 1.5s linear 0.5s infinite;
}

.animation--pulse-y {
  animation: pulse-y 1.5s linear 0.5s infinite;
}

.animation--pulse {
  animation: pulse 1.5s linear 0.5s infinite;
}

</style>
