<template>
  <div class="sidebar sticky-bar p-4 rounded shadow">
    <div class="progress2 progress-moved" v-if="loading">
      <div class="progress-bar2"></div>
    </div>
    <div class="widget mb-2 pb-2 border-bottom">
      <h4 class="widget-title">
        {{ $t("brokerage.sort.options") }} ({{ count }})
      </h4>
      <div class="mt-4 mb-5">
        <div class="form-group mb-2">
          <select
            class="form-select form-control"
            id="sort"
            v-model="sort"
            @change="onClick"
          >

            <option selected="selected" value="length">
              {{ $t("brokerage.sort.length") }}
            </option>
            <option value="buildyear">
              {{ $t("brokerage.sort.buildyear") }}
            </option>
            <option value="price">
              {{ $t("brokerage.sort.price") }}
            </option>
            <option value="brand">
              {{ $t("brokerage.sort.brand") }}
            </option>
          </select>
        </div>
      </div>
      <h4 class="widget-title">
        {{ $t("brokerage.filters.options") }}
      </h4>
      <div class="mt-4">
        <div class="form-group mb-2">
          <div class="custom-control custom-checkbox">
            <input
              type="checkbox"
              class="custom-control-input"
              id="forsale"
              v-model="forsale"
              @change="onClick"
            />
            <label
              class="custom-control-label text-muted font-weight-light"
              for="forsale"
              >{{ $t("brokerage.filters.option-available") }}</label
            >
          </div>
        </div>

        <div class="form-group mb-2">
          <div class="custom-control custom-checkbox">
            <input
              type="checkbox"
              class="custom-control-input"
              id="update:dealership"
              v-model="dealership"
              @change="onClick"
            />
            <label
              class="custom-control-label text-muted font-weight-light"
              for="update:dealership"
              >{{ $t("brokerage.filters.option-newonly") }}</label
            >
          </div>
        </div>

        <div class="form-group mb-2">
          <div class="custom-control custom-checkbox">
            <input
              type="checkbox"
              class="custom-control-input"
              id="update:brokerage"
              v-model="brokerage"
              @change="onClick"
            />
            <label
              class="custom-control-label text-muted font-weight-light"
              for="update:brokerage"
              >{{ $t("brokerage.filters.option-usedonly") }}</label
            >
          </div>
        </div>
      </div>
    </div>

    <div class="widget mb-3 pb-2" v-if="lengthsSummary">
      <h4 class="widget-title">{{ $t("brokerage.filters.length") }}</h4>
      <div class="row p-0">
        <div class="form-group col-6">
          <select
            class="form-select form-control"
            v-if="lengthsSummary.lengthRange"
            id="minlength"
            v-model="minlength"
            @change="onClick"
          >
            <option
              selected="selected"
              disabled
              :value="lengthsSummary.minLength"
            >
              {{ $t("brokerage.filters.minimal") }}
            </option>
            <option
              v-for="lengthItem in lengthsSummary.lengthRange"
              :key="lengthItem.id"
              :value="lengthItem.length"
            >
              {{ lengthItem.length / 100 }} m
            </option>
          </select>
        </div>
        <div class="form-group col-6">
          <select
            class="form-select form-control"
            v-if="lengthsSummary.lengthRange"
            id="maxlength"
            v-model="maxlength"
            @change="onClick"
          >
            <option
              selected="selected"
              disabled
              :value="lengthsSummary.maxLength"
            >
              {{ $t("brokerage.filters.maximum") }}
            </option>
            <option
              v-for="lengthItem in lengthsSummary.lengthRange"
              :key="lengthItem.id"
              :value="lengthItem.length"
            >
              {{ lengthItem.length / 100 }} m
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="widget mb-3 pb-2">
      <h4 class="widget-title">{{ $t("brokerage.filters.price-range") }}</h4>
      <div class="row p-0">
        <div class="form-group col-6">
          <select
            class="form-select form-control"
            v-if="prices"
            id="minprice"
            v-model="minprice"
            @change="onClick"
          >
            <option disabled :value="prices.minPrice" selected>
              {{ $t("brokerage.filters.minimal") }}
            </option>
            <option
              v-for="priceItem in prices.priceRange"
              :key="priceItem.id"
              :value="priceItem.Price"
            >
              &euro; {{ numberWithCommas(priceItem.Price) }}
            </option>
          </select>
        </div>
        <div class="form-group col-6">
          <select
            class="form-select form-control"
            v-if="prices"
            id="maxprice"
            v-model="maxprice"
            @change="onClick"
          >
            <option disabled :value="prices.maxPrice" selected>
              {{ $t("brokerage.filters.maximum") }}
            </option>
            <option
              v-for="priceItem in prices.priceRange"
              :key="priceItem.id"
              :value="priceItem.Price"
            >
              &euro; {{ numberWithCommas(priceItem.Price) }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="widget mb-3 pb-2">
      <h4 class="widget-title">{{ $t("brokerage.filters.brands") }}</h4>
      <div class="form-group mt-0 col-md-12">
        <select
          class="form-select form-control"
          v-if="brandNames"
          id="brand"
          v-model="brands"
          @change="onClick"
        >
          <option value="all">
            {{ $t("brokerage.filters.all") }}
          </option>
          <option
            v-for="brand in orderedBrands"
            :key="brand.id"
            :value="brand.id"
          >
            {{ brand.attributes.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="widget mb-3 pb-2">
      <h4 class="widget-title">{{ $t("brokerage.filters.buildyear") }}</h4>
      <div class="form-group mt-0 col-md-12">
        <select
          class="form-select form-control"
          v-if="buildyearRange"
          id="buildyear"
          v-model="buildyear"
          @change="onClick"
        >
          <option value="all">
            {{ $t("brokerage.filters.all") }}
          </option>
          <option
            v-for="buildyear in buildyearRange.buildYearRange"
            :key="buildyear.id"
            :value="buildyear.buildingyear"
          >

              {{ buildyear.buildingyear }} -
              {{ makeBuildYear(buildyear.buildingyear) }}

          </option>
        </select>
      </div>
    </div>

    <div class="widget mb-0 pb-2">
      <div class="form-group mt-4 mb-0">
        <button class="btn-outline-secondary btn-block" @click="resetFilter()">
          {{ $t("brokerage.filters.reset-button") }}
        </button>
      </div>
    </div>
    <div class="widget mb-0 pb-3">
      <div class="form-group mt-2 mb-0">
        <div
          class="text-lighter"
          v-html="$t('brokerage.filters.instant_filters_explanation')"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "searchSidebar.vue",
  data() {
    return {
      minlength: 0,
      maxlength: 10000,
      buildyear: "all",
      brands: "all",
      minprice: 0,
      maxprice: 50000000,
      forsale: false,
      dealership: false,
      brokerage: false,
      loading: false,
      sort: "length",
    };
  },
  props: {
    brandNames: {
      type: Object,
    },
    lengthsSummary: {
      type: Object,
    },
    buildyearRange: {
      type: Object,
    },
    prices: {
      type: Object,
    },
    count: {
      type: Number,
    },
  },
  mounted() {
    this.eventBus.on("loadingSearch", (args) => {
      if (args === true) {
        this.loading = true;
      }
      if (args === false) {
        this.loading = false;
      }
    });
    this.minprice = this.prices.minPrice;
    this.maxprice = this.prices.maxPrice;
    this.minlength = this.lengthsSummary.minLength;
    this.maxlength = this.lengthsSummary.maxLength;
    if (this.$route.params.brands) {
      this.brands = this.$route.params.brands;
      this.dealership = true;
      this.eventBus.emit("searchCriteria", this.$data);
    }
  },
  watch: {
    brokerage(val) {
      // console.log("brokerage " + val);

      if (val === true) {
        this.dealership = false;
        this.eventBus.emit("searchCriteria", this.$data);
      }
    },
    dealership(val) {
      // console.log("dealership " + val);
      if (val === true) {
        this.brokerage = false;
        this.eventBus.emit("searchCriteria", this.$data);
      }
    },
  },
  computed: {
    orderedBrands: function () {
      let brandProperties = this.brandNames.data;

      brandProperties.sort((a, b) =>
        a.attributes.name > b.attributes.name ? 1 : -1
      );
      return brandProperties;
    },
  },
  methods: {
    makeBuildYear(year) {
      const maxYear = this.buildyearRange.maxYear;
      if (year + 20 < maxYear) {
        return Number(year + 20);
      } else {
        return maxYear;
      }
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    resetFilter() {
      Object.assign(this.$data, this.$options.data.apply(this));
      this.eventBus.emit("searchCriteria", this.$data);
      this.minprice = this.prices.minPrice;
      this.maxprice = this.prices.maxPrice;
      this.minlength = this.lengthsSummary.minLength;
      this.maxlength = this.lengthsSummary.maxLength;
    },
    // onChange(event) {
    //   this.$emit(event.target.id, event.target.value);
    // },
    // sessionStorage.setItem("searchCriteria", JSON.stringify(this.$data));
    onClick() {
      // console.log(this.$data);
      this.eventBus.emit("searchCriteria", this.$data);
    },
  },
};
</script>
<style scoped>
.progress2 {
  padding: 0px;
  padding-bottom: 20px;
  border-radius: 10px;
}

.progress-bar2 {
  height: 8px;
  border-radius: 10px;
  transition: 0.4s linear;
  transition-property: width, background-color;
}

.progress-moved .progress-bar2 {
  width: 100%;
  background-color: #1a1f3b;
  animation: progressAnimation 0.6s;
}

@keyframes progressAnimation {
  0% {
    width: 0%;
    background-color: #1a1f3b;
  }
  100% {
    width: 100%;
    background-color: #6a6d80;
  }
}

.custom-control.custom-checkbox input[type="checkbox"] {
  margin-right: 10px;
}
.custom-control.custom-checkbox label {
  font-weight: 300;
}
.widget-title {
  font-size: 18px;
}
.custom-control-label:after,
.custom-control-label:before {
  top: 2px;
}
.custom-control-label:after {
  background: no-repeat 50%/50% 50%;
}
.custom-control-label:after,
.custom-control-label:before {
  position: absolute;
  top: 0.25rem;
  left: 0rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  border-radius: 4px;
}
.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}
input[type="checkbox" i] {
  background-color: initial;
  cursor: default;
  appearance: auto;
  box-sizing: border-box;
  margin: 3px 3px 3px 4px;
  padding: initial;
  border: initial;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label:after {
  background-image: url("@/assets/images/checkbox_icon.svg");
  border-radius: 4px;
  color: #ff0000;
}
.custom-control-label:after {
  border: 1px solid #495057;
}
.shadow {
  box-shadow: 0 0 10px rgb(60 72 88 / 15%) !important;
}
</style>
